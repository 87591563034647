import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import React, { ChangeEvent, Component } from "react";
import { FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AddImageIcon from "images/image-upload.svg";
import { NotificationManager } from "react-notifications";
import ImageUtil from "utils/imageUtil";
import SendMessageIcon from "../../images/send-message.svg";
import IChatMessage from "../../interfaces/IChatMessage";
import { fetchChat, fetchChatAttachmentSasToken, sendChat } from "../../store/actions/chatActions";
import { RootState } from "../../store/reducers/rootReducer";
import BasicModal from "../material/modal/basicModal";
import ChatInterfaceMessage from "./components/chatInterfaceMessage";
import IChatInterfaceProps, {
  IChatInterfaceDispatchProps,
  IChatInterfaceStateProps,
} from "./interfaces/IChatInterfaceProps";
import IChatInterfaceState from "./interfaces/IChatInterfaceState";

class ChatInterface extends Component<IChatInterfaceProps, IChatInterfaceState> {
  private readonly initialFocusElementRef: React.RefObject<HTMLDivElement>;
  private readonly fileUploadRef: React.RefObject<HTMLInputElement>;

  public constructor(props: IChatInterfaceProps) {
    super(props);

    const state: IChatInterfaceState = {
      newMessageContent: "",
    };
    this.state = state;

    this.renderModalFooter = this.renderModalFooter.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleOnEntering = this.handleOnEntering.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onChatMessageChange = this.onChatMessageChange.bind(this);
    this.scrollToLastMessage = this.scrollToLastMessage.bind(this);
    this.triggerFileSelection = this.triggerFileSelection.bind(this);
    this.onFileUploadClick = this.onFileUploadClick.bind(this);
    this.handleFileSelection = this.handleFileSelection.bind(this);
    this.onDeleteAttachment = this.onDeleteAttachment.bind(this);

    this.initialFocusElementRef = React.createRef();
    this.fileUploadRef = React.createRef();
  }

  public componentDidUpdate(): void {
    if (this.initialFocusElementRef) {
      this.scrollToLastMessage();
    }
  }

  private clearContents(): void {
    this.setState({
      newMessageContent: "",
      attachedImage: undefined,
    });
  }

  private async sendMessage(): Promise<void> {
    const image = this.state.attachedImage ? encodeURIComponent(this.state.attachedImage) : undefined;

    if (this.state.newMessageContent || this.state.attachedImage) {
      this.props.onSendChat(this.props.topicId, this.state.newMessageContent, this.props.chatType, image);
      this.clearContents();
    }
  }
  private onDeleteAttachment(): void {
    this.setState({
      attachedImage: undefined,
    });
  }

  private renderModalFooter(): JSX.Element {
    const hasMessageAttachment = this.state.attachedImage != null;

    const adjustTextareaHeight = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
      const textarea = event.target;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    return (
      <div className="g-chat-footer">
        <div
          className={
            hasMessageAttachment ? "g-chat-message-input-container has-attachment" : "g-chat-message-input-container"
          }
        >
          {this.state.attachedImage && (
            <div className="selected-image-container">
              <img src={this.state.attachedImage} alt="selected" />
              <div className="delete-image" data-test="delete-image" onClick={this.onDeleteAttachment} />
            </div>
          )}
          <FormControl
            as="textarea"
            rows={1}
            maxLength={500}
            placeholder={LanguageProvider.t(TranslationMapper.components.chats.message_placeholder)}
            aria-label="Message"
            className="g-message-textarea"
            value={this.state.newMessageContent}
            onChange={(e): void => {
              this.onChatMessageChange(e as React.ChangeEvent<HTMLTextAreaElement>);
              adjustTextareaHeight(e as React.ChangeEvent<HTMLTextAreaElement>);
            }}
            onKeyDown={(e): void => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                this.sendMessage().then(() => {
                  adjustTextareaHeight(e as React.ChangeEvent<HTMLTextAreaElement>);
                });
              }
            }}
          ></FormControl>
        </div>

        <img
          src={SendMessageIcon}
          onClick={this.sendMessage}
          alt="send message"
          className="g-send-message-button"
          data-test="g-send-message-button"
        />

        <img
          src={AddImageIcon}
          onClick={this.triggerFileSelection}
          alt="add attachment to message"
          className="g-attachment-button"
          data-test="g-attachment-button"
        />
      </div>
    );
  }

  private onChatMessageChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({ newMessageContent: event.target.value });
  }

  private handleOnEntering(): void {
    this.props.onFetchChat(this.props.topicId, this.props.chatType);
  }

  private onCloseModal(): void {
    this.props.onClose();
  }
  private triggerFileSelection(): void {
    if (!this.fileUploadRef || !this.fileUploadRef.current) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.global.errors.general));
      return;
    }

    this.fileUploadRef.current.click();
  }

  private onFileUploadClick(e: React.MouseEvent<HTMLInputElement>): void {
    // Reset input onClick to make sure upload is possible
    e.currentTarget.files = null;
    e.currentTarget.value = "";
  }

  private async handleFileSelection(e?: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const selectorFile = e?.target?.files?.[0];

    if (!e || !selectorFile) {
      return;
    }

    const maxUncompressedSize = 1024 * 1024 * 15; // = 15Mb

    if (selectorFile.size > maxUncompressedSize) {
      NotificationManager.error(LanguageProvider.t(TranslationMapper.components.chats.error_image_size));

      // Clear HTML input, otherwise no new files can be entered once a incorrect file has been added.
      e.target.value = "";
      e.target.files = null;
    } else {
      switch (selectorFile.type) {
        case "image/jpeg":
        case "image/png":
          await ImageUtil.compressToBase64Async(selectorFile).then((image) => this.setState({ attachedImage: image }));
          break;
        default:
          NotificationManager.error(LanguageProvider.t(TranslationMapper.components.chats.error_image_file));
      }
    }
  }

  private renderChatMessage(message: IChatMessage, index: number): JSX.Element {
    const isLastMessage = this.props.chatConversation.length - 1 === index;
    const onOpenAttachment = message.attachmentUri
      ? (): void => this.props.onOpenChatAttachment(message.chatId)
      : undefined;

    const chatMessage = (
      <div ref={isLastMessage ? this.initialFocusElementRef : undefined} key={index}>
        {this.props.topicId === message.topicId && (
          <ChatInterfaceMessage
            date={message.createdOn instanceof Date ? message.createdOn : new Date(message.createdOn)}
            senderName={message.senderName}
            messageContent={message.contents}
            isFromCurrentUser={this.props.currentUserId === message.senderId}
            attachmentUri={message.attachmentSasUri}
            onOpenAttachment={onOpenAttachment}
          />
        )}
      </div>
    );

    if (this.initialFocusElementRef) {
      this.scrollToLastMessage();
    }

    return chatMessage;
  }

  private scrollToLastMessage(): void {
    if (!this.initialFocusElementRef?.current) {
      return;
    }

    this.initialFocusElementRef.current.scrollIntoView();
  }

  public render(): JSX.Element {
    return (
      <BasicModal
        onEntering={this.handleOnEntering}
        header={LanguageProvider.t(TranslationMapper.components.chats.chat_header)}
        onClose={this.onCloseModal}
        showModal={this.props.showChatInterface}
        className="g-chat-interface"
        footer={this.renderModalFooter()}
        bodyClassName={this.state.attachedImage ? "message-input-expanded" : undefined}
        showFullscreen={true}
        isHeaderFixed={true}
        onRefreshChat={this.props.onFetchChat}
        isLoading={this.props.isLoading}
        topicId={this.props.topicId}
        chatType={this.props.chatType}
      >
        {this.props.chatConversation && (
          <div className="g-chat-interface-messages-container">
            {this.props.chatConversation.map((c: IChatMessage, index: number) => this.renderChatMessage(c, index))}
          </div>
        )}
        <input
          style={{ display: "none" }}
          id="chat-attachment-input"
          ref={this.fileUploadRef}
          type="file"
          accept="image/*;capture=camera"
          onClick={this.onFileUploadClick}
          onChange={this.handleFileSelection}
        />
      </BasicModal>
    );
  }
}

const mapStateToProps = (state: RootState): IChatInterfaceStateProps => ({
  chatConversation: state.chatState.chatConversation,
  isLoading: state.chatState.isLoading,
  currentUserId: state.userState.user?.userId,
});

const mapDispatchToProps: IChatInterfaceDispatchProps = {
  onFetchChat: fetchChat,
  onSendChat: sendChat,
  onOpenChatAttachment: fetchChatAttachmentSasToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChatInterface));
